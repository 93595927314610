import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SectionWithColumnLayout from 'components/SectionWithColumnLayout'

const Agab = ({ data }) => (
  <Layout
    pageTitle='Kundcase - AGAB'
    headerBackgroundImage={data.header.childImageSharp.gatsbyImageData}
    headerBackgroundPosition='100% 60%'
  >
    <oma-grid-row>
      <SectionWithColumnLayout title='Kundcase'>
        <h2 className='section__sub-heading'>AGAB</h2>
        <p className='section__text'>
          Patrik Eriksson driver sedan 2017 AGAB som utför markentreprenad, ofta
          som underentreprenör till de större företagen, något som blir mer och
          mer vanligt i branschen. AGAB består av Patrik som äger bolaget och
          Marcus Wall som är anställd och jobbar som anläggningsarbetare. I
          företaget finns 2 st grävmaskiner och Patrik hyr ofta in någon med
          F-skattsedel så att båda maskinerna kan vara igång samtidigt.
        </p>
        <p className='section__text'>
          Patrik startade AGAB efter en konkurs av ett annat bolag. Det var
          också då han kom i kontakt med Ertan.{' '}
          <i>
            Jag träffade Ertan genom STC (Schakt och Transport Centralen) där de
            skötte all ekonomi och när konkursen tyvärr var ett faktum i mitt
            gamla bolag klev Ertan in och hjälpte oss igenom detta och gjorde
            att det gick väldigt, väldigt smidigt. Det var därför ett naturligt
            steg att fortsätta jobba ihop med Ertan när jag drog igång AGAB.
          </i>
        </p>
        <p>
          <i>
            Det var också Ertan som introducerade Fortnox för oss. Min bror
            hjälper till att fakturera och gör det då genom Fortnox, ett väldigt
            bra verktyg som ger mig möjlighet att följa t ex kassaflödet som är
            så viktig att ha koll på, hela tiden. Sedan sköter Ertan alla
            bokföring, moms, skatter, bokslut mm och jag får ett mail med
            ”instruktioner” gör si, gör så, betala detta osv - supersmidigt,
            säger Patrik. Ertan är också vårt bollplank när det gäller
            rådgivning och kommer med tips om hur jag kan tänka och agera så det
            blir mesta möjliga ekonomiska vinning för bolaget och mig. Jag har
            löpande kontakt med Ertan ungefär var 14:e dag, vilket passar mig
            perfekt. Jag kan varmt rekommendera Ertan till både små och stora
            bolag som behöver någon trygg partner när det kommer till ekonomi
            och bokföring.
          </i>
        </p>
        <p className='section__text'>
          <i>
            I framtidsplanerna ingår det inte att utöka med fler maskiner eller
            anställda, men jag vill inte heller vara ensam i bolaget eftersom
            man ofta behöver vara två, en i maskinen och en utanför. Men det är
            inte bara det utan det är skönt att ha någon att ”bolla” och jobba
            ihop med också och jag stortrivs i den situation jag befinner mig
            nu,
          </i>{' '}
          avslutar Patrik.
        </p>
      </SectionWithColumnLayout>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    header: file(relativePath: { eq: "agab.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }
`

export default Agab
